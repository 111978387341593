// @flow

import { graphql } from "gatsby";
import React, { useEffect, useState } from "react";
import { t, useLocale, addLocale } from "ttag";
import { navigate } from "@reach/router";
import { mapPropertiesForSearch } from "@utils/c21-api/mappers/property";
import PropertyOverviewSearchFactory from "@utils/search/property-overview";
import { type Search, type PropertyOverviewFilter } from "@utils/search/types";
import {
    type SortEvent,
    EVENT_MAP,
    type FilterUpdateEvent,
    type PageImpressionParams,
    type BannerImpressionParams,
} from "../models/events";
import { PushEvent } from "../components/Tracker/Tracker";
import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";
import {
    Meta,
    PropertyFilter,
    PropertyOverviewBreadcrumb,
    PropertyOverviewSort,
    PropertySearchMap,
    PropertySearchResults,
} from "@components";
import { SearchPage } from "@containers";
import {
    TrackPageImpression,
    TrackBannerImpression,
} from "../components/Tracker/Tracker";
import settings from "../utils/settings";

const PropertyOverviewPageTemplate = ({
    data: {
        c21Translation: { translations },
        contentfulNavigation: { navigationItems },
        footerItems,
        contentfulWebLandingPage: { pageContent },
    },
    pageContext,
}: *) => {
    const { locale, slug, translationSlugs } = pageContext;
    const sendToGTM = useGTMDispatch();
    const countryCode = settings.COUNTRY_CODE.toUpperCase();

    addLocale(locale, translations);
    useLocale(locale);

    const [propertyOverviewSearch: Search, initialize] = useState();
    const [searchParams, setSearchParams] = useState();
    const [previousFilterQs, setPreviousFilter] = useState("");

    const [properties, setProperties] = useState([]);
    const [qs, setQs] = useState();
    const [metaData, setMetaData] = useState({
        pageCount: 0,
        totalCount: 0,
        facets: undefined,
    });
    const [numberOfResults, setNumberOfResults] = useState();

    const searchPageBanner = pageContent?.filter((item) => {
        if (
            item.__typename === "ContentfulSearchPageBanner" &&
            ["ALL", countryCode || ""].includes(item.country)
        ) {
            return item;
        }
    });

    const bannerImageLink =
        searchPageBanner &&
        searchPageBanner.map(
            (item) => item?.imageLink?.contentfulInternalLink?.slug,
        );

    const bannerLinkTitle =
        searchPageBanner &&
        searchPageBanner.map((item) => item?.imageLink?.linkTitle);

    useEffect(() => {
        const paramsString = location.search;
        let searchParams = new URLSearchParams(paramsString);

        const pageParams: PageImpressionParams = {
            agency: searchParams.get("agency")
                ? {
                      id: searchParams.get("agency") || "",
                      name: "",
                      city: "",
                      postalcode: "",
                      country: "",
                  }
                : null,
            agencypage: "false",
            language: locale,
            pagename: "Property overview",
            typetemplate: "property-overview",
        };
        TrackPageImpression(sendToGTM, EVENT_MAP.PageImpression, pageParams);

        const bannerImpressionParams: BannerImpressionParams = {
            name: "searchpagebanner",
            language: locale,
            targeturl: bannerImageLink,
            targetname: bannerLinkTitle,
        };

        TrackBannerImpression(
            sendToGTM,
            EVENT_MAP.SearchPageBannerImpression,
            bannerImpressionParams,
        );
    }, []);

    const onFilterChanged = (filter: PropertyOverviewFilter) => {
        if (filter.location && filter.location === null) {
            delete filter.location;
        }
        setPreviousFilter(location.search);
        propertyOverviewSearch?.setFilter(filter);
        applySearch();
    };

    const onFilterCleared = () => {
        initialize(
            PropertyOverviewSearchFactory.fromInitialValues({
                sort: searchParams?.sort,
            }),
        );
    };

    const onSortChanged = (sort) => {
        const event: SortEvent = {
            order: sort.direction,
            field: sort.field,
            type: EVENT_MAP.SortEvent,
        };
        PushEvent(event, sendToGTM);
        propertyOverviewSearch?.setSort(sort);
        applySearch();
    };

    const onPageChanged = (page) => {
        propertyOverviewSearch?.setPage(page);
        applySearch();
    };

    const applySearch = () => {
        setSearchParams(propertyOverviewSearch?.current); // do not let the filters and sorting dropdowns flicker
        propertyOverviewSearch
            ?.search()
            .then((result) => {
                setMetaData(result.data._meta);
                return mapPropertiesForSearch(result.data.data, locale);
            })
            .then((properties) => {
                setProperties(properties);
                setSearchParams(propertyOverviewSearch?.current); // re-apply boundaries on search params with new results
            });

        setQs(propertyOverviewSearch?.toBrowserQueryString());
    };

    const handleFilterEvent = () => {
        // Prevents an error when accessing a non-existing array element. Only occurs when both value are exactly the same
        if (previousFilterQs === location.search) {
            return;
        }

        const filtersParams: URLSearchParams = new URLSearchParams(
            location.search,
        );
        const previousFiltersParams: URLSearchParams = new URLSearchParams(
            previousFilterQs,
        );

        const filterCurrent = [];
        const filterPrevious = [];

        for (let entry of filtersParams.entries()) {
            filterCurrent.push(entry);
        }

        for (let entry of previousFiltersParams.entries()) {
            filterPrevious.push(entry);
        }

        const filterUpateEvent: FilterUpdateEvent = {
            fieldname: "",
            fieldtype: "",
            value: "",
            action: "",
            selection: filterCurrent,
            type: EVENT_MAP.FilterUpdateEvent,
        };

        if (filterPrevious.length > 0 || filterCurrent.length > 0) {
            if (filterPrevious.length > filterCurrent.length) {
                let i = 0;
                if (filterCurrent.length > 0) {
                    while (
                        filterCurrent[i] &&
                        filterCurrent[i][0] === filterPrevious[i][0]
                    ) {
                        i++;
                    }
                }

                filterUpateEvent.fieldname = filterPrevious[i][0];
                filterUpateEvent.fieldtype =
                    filterPrevious[i][1] === "true" ||
                    filterPrevious[i][1] === "false"
                        ? "bullet"
                        : "select";
                filterUpateEvent.value = filterPrevious[i][1];
                filterUpateEvent.action = "remove";
            } else {
                let i = 0;

                if (filterCurrent.length > 0 && filterPrevious.length > 0) {
                    while (
                        filterPrevious[i] &&
                        filterPrevious[i][0] === filterCurrent[i][0] &&
                        filterPrevious[i] &&
                        filterPrevious[i][1] === filterCurrent[i][1]
                    ) {
                        i++;
                    }
                }

                filterUpateEvent.fieldname = filterCurrent[i][0];
                filterUpateEvent.fieldtype =
                    filterCurrent[i][1] === "true" ||
                    filterCurrent[i][1] === "false"
                        ? "bullet"
                        : "select";
                filterUpateEvent.value = filterCurrent[i][1];
                filterUpateEvent.action = "apply";
            }
            PushEvent(filterUpateEvent, sendToGTM);
        }
    };

    useEffect(() => {
        if (!propertyOverviewSearch) return;

        applySearch();
    }, [propertyOverviewSearch]);

    const browser = typeof window !== "undefined" && window.location.search;

    useEffect(() => {
        initialize(
            PropertyOverviewSearchFactory.fromQs(window.location.search),
        );
    }, [browser]);

    useEffect(() => {
        if (!propertyOverviewSearch) return; // prevent flicker of qs on page load

        navigate(`${slug}${qs || ""}`, {
            replace: true,
        });
        handleFilterEvent();
    }, [qs]);

    useEffect(() => {
        if (!metaData) return;

        setNumberOfResults(metaData.totalCount);
    }, [metaData]);

    const optionsWithQuery = {};

    Object.keys(translationSlugs).forEach(
        (key) =>
            (optionsWithQuery[key] = `${translationSlugs[key]}${
                propertyOverviewSearch?.toBrowserQueryString() || ""
            }`),
    );

    const filter = (
        <PropertyFilter
            onFilterChanged={onFilterChanged}
            onFilterCleared={onFilterCleared}
            currentFilter={searchParams?.filter}
            facets={metaData.facets}
            language={locale}
            filterType={"propertyFilter"}
            countryFilterWhitelist={
                process.env.GATSBY_ALLOWED_COUNTRIES?.split(",") || []
            }
        />
    );

    const map = <PropertySearchMap properties={properties} />;

    const sort = (
        <PropertyOverviewSort
            numberOfResults={numberOfResults}
            currentSort={searchParams?.sort}
            onSortChanged={onSortChanged}
        />
    );

    const results = (
        <PropertySearchResults
            properties={properties}
            numberOfResults={numberOfResults}
            currentPage={searchParams?.page}
            onPageChanged={onPageChanged}
            Header={sort}
        />
    );

    return (
        <>
            <Meta
                title={t`title.property-overview`}
                description={t`meta.description.property-overview`}
                translationSlugs={translationSlugs}
                locale={locale}
            />
            <SearchPage
                headerProps={{
                    locale,
                    navigationItems,
                    translationSlugs: optionsWithQuery,
                    footerItems,
                }}
                Filter={filter}
                Map={map}
                Results={results}
                searchBanner={pageContent}
                Breadcrumb={<PropertyOverviewBreadcrumb locale={locale} />}
            />
        </>
    );
};
export default PropertyOverviewPageTemplate;

export const query = graphql`
    query ($locale: String!, $country: String!) {
        c21Translation(locale: { eq: $locale }) {
            translations(
                keys: [
                    "property-overview.*"
                    "api.*"
                    "slug.property-detail"
                    "common.filters.*"
                    "common.text.yes"
                    "common.text.no"
                    "common.text.maybe"
                    "common.text.language"
                    "common.price.on-demand"
                    "map.*"
                    "title.property-overview"
                    "meta.description.property-overview"
                    "breadcrumbs.property-overview.*"
                    "agency.header.button.text"
                    "slug.estimation-tool"
                ]
            )
        }
        contentfulNavigation(
            title: { eq: "Main Navigation" }
            node_locale: { eq: $locale }
            country: { eq: $country }
        ) {
            ...ContentfulNavigation
        }
        footerItems: contentfulNavigation(
            title: { eq: "Footer" }
            node_locale: { eq: $locale }
            country: { eq: $country }
        ) {
            ...ContentfulNavigation
        }

        contentfulWebLandingPage(
            internalLabel: { eq: "search page" }
            node_locale: { eq: $locale }
        ) {
            pageContent {
                ... on ContentfulSearchPageBanner {
                    id
                    typeOfSearch
                    imageLink {
                        ... on ContentfulLink {
                            id
                            linkTitle
                            externalLink
                            contentfulInternalLink {
                                ... on ContentfulWebLandingPage {
                                    slug
                                }
                            }
                        }
                    }
                    image {
                        fluid(maxWidth: 2344, quality: 80) {
                            ...GatsbyContentfulFluid_withWebp
                        }
                        title
                        description
                    }
                    country
                }
            }
        }
    }
`;
